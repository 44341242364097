
import { handleErrorUI } from '../../util/error';

export default {
  data() {
    return {
      data: []
    };
  },
  async mounted() {
    try {
      const { logs } = await this.$repo.log.getLogs();
      this.data = logs;
    } catch (err) {
      handleErrorUI(err);
    }
  }
};
